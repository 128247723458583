<template>
  <CTabs class="zq--tabs">
    <CTab title="Notification"><NotificationsTable :statuses="statuses"/></CTab>
    <CTab title="Member"><MemberMessagesTable :statuses="statuses"/></CTab>
  </CTabs>
</template>
<script>

import NotificationsTable from "../inbox/tables/NotificationsTable";
import MemberMessagesTable from "../inbox/tables/MemberMessagesTable";

export default {
  name: 'Draft',
  components: {
    NotificationsTable,
    MemberMessagesTable,
  },
  data() {
    return {
      statuses: ['Draft']
    }
  },
};
</script>

<style lang="scss">

</style>